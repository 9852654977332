import React, { useEffect, useRef, useState } from "react";
import { sumArray } from "../../helper";
import "./Projects.css";
import { projects } from "../../data";
import Card from "./Card";

const tabs = [
  { name: "All" },
  { name: "Apps" },
  { name: "Freelance" },
  { name: "School" },
];

const Projects = () => {
  const [displayableProjects, setDisplayableProjects] = useState(projects);
  const [activeIndex, setActiveIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [indicatorWidth, setIndicatorWidth] = useState(0);

  const itemEls = useRef([]);

  useEffect(() => {
    const prevEl = itemEls.current.filter((_, index) => index < activeIndex);
    setOffset(sumArray(prevEl.map((item) => item.offsetWidth)));
    setIndicatorWidth(itemEls.current[activeIndex].offsetWidth);
  }, [activeIndex]);

  const setProjects = (category) => {
    if (category === "All") {
      return setDisplayableProjects(projects);
    }
    const pro = projects.filter(
      (item) => item.category.toLowerCase() === category.toLowerCase()
    );
    setDisplayableProjects(pro);
  };

  return (
    <section id="projects">
      <div className="section__wrapper projects__container">
        <div className="section__header center">
          <h2 className="primary__title__2">Projects</h2>
        </div>
        <nav>
          {tabs.map((tab, index) => (
            <button
              ref={(el) => (itemEls.current[index] = el)}
              key={index}
              onClick={() => {
                setActiveIndex(index);
                setProjects(tab.name);
              }}
              className={activeIndex === index ? "active" : ""}
            >
              {tab.name}
            </button>
          ))}
          <span
            className="active__indicator"
            style={{
              left: `${offset}px`,
              width: `${indicatorWidth}px`,
            }}
          ></span>
        </nav>
        <div className="card__container">
          {displayableProjects.map((project, index) => (
            <Card
              key={index}
              title={project.title} className="primary__title__2"
              image={project.image}
              data={project.data}
              stack={project.stack}
              demoLink={project.data.demoLink}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
